import "../styles/globals.css";

// import AuthLayout from '../layouts/authLayout'

import { StateProvider } from "../state/index";
import reducer from "../reducers";
import { initialState } from "../state/initialState";
import Head from "next/head";
import dynamic from "next/dynamic";
import { wrapper } from "../store/store";
import Script from "next/script";
import { useEffect } from "react";
import { IO_API_SERVICE } from "../services/ioApi";

// import LocationModal from "../components/Modals/LocationModal";

const InfoModal = dynamic(() => import("../components/Modals/InfoModal"), {
   ssr: false
});
const LoginModal = dynamic(() => import("../components/Modals/LoginModal"), {
   ssr: false
});
const ActionModal = dynamic(() => import("../components/Modals/ActionModal"), {
   ssr: false
});
const ConfirmModal = dynamic(() => import("../components/Modals/ConfirmModal"), {
   ssr: false
});
const CommonLoader = dynamic(() => import("../components/Loaders/CommonLoader"), {
   ssr: false
});

// const layouts = {
//   L1: AuthLayout,
//   L2: Layout
// }

function MyApp({ Component, pageProps }) {
   // console.log({Component})
   // const view = Component.layout
   // const Layout =  Layout
   //  const Context = Provider[Component.provider];
   //  // if (Component.getLayout) {
   //  //   return Component.getLayout(
   //  //     <Layout>
   //  //       <Component {...pageProps} />
   //  //     </Layout>
   //  //   )
   //  // }

   let initialData = {
      ...initialState,
      ...pageProps
   };
   // console.log({ user: pageProps?.users });

   useEffect(() => {
      if (pageProps?.users?.userInfo?.id) {
         const payload = {
            userId: pageProps.users.userInfo.id,
            onesignalId: "",
            isOnApplozic: "",
            deviceType: 99,
            deviceModel: navigator?.userAgent || "",
            gcmRegId: "",
            iosRegId: ""
         };
         IO_API_SERVICE.postUserActive(payload).catch(error => {
            console.error("Error posting user active status:", error);
         });
      }
   }, [pageProps?.users?.userInfo?.id]);

   const getLayout = Component.getLayout || (page => page);
   return (
      <StateProvider reducers={reducer} initialState={initialData}>
         {" "}
         <Head>
            <title>Playo</title>
            <meta name="robots"></meta>
            {/* <meta name='googlebot' content='noindex'></meta> */}

            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
               defer
               href="https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
               rel="stylesheet"
            />
            <link defer href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Poiret+One&display=swap" rel="stylesheet" />

            {/* <Script
               src='https://maps.googleapis.com/maps/api/js?key=AIzaSyB9q4uF6xjrDG-n2jvClxrtOV_jSXUAPUY &libraries=places'
               strategy='beforeInteractive'
            /> */}
            {/* <Script
               src='https://maps.googleapis.com/maps/api/js?key=AIzaSyCRkbJRNHPRavo1CJVxKOJqg-qkSMA62tY&libraries=places'
               strategy='beforeInteractive'
            /> */}
         </Head>
          <Script
            strategy='lazyOnload' async
            src={`https://www.googletagmanager.com/gtag/js?id=G-16ZBH4C91C`}
         />
         <Script >
            {` window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-16ZBH4C91C');`}
                  {/* gtag('config', 'GTM-54NDM4N');`} */}
         </Script>
         {/*  */}
         <InfoModal />
         <ActionModal />
         <LoginModal />
         <ConfirmModal />
         <CommonLoader />
         {/* <LocationModal /> */}
         {getLayout(<Component {...pageProps} />)}
         {/* <Layout
            filter={pageProps?.showFilter}
            allSports={pageProps?.allSports}
            footer={pageProps?.footer}
            bg={pageProps?.bg}
            layout={pageProps?.layout}
            headerLogo={pageProps?.headerLogo}
         >
            

            <Component {...pageProps} />
         </Layout> */}
      </StateProvider>
   );
}

export default wrapper.withRedux(MyApp);
